/* eslint-disable*/
import axios from 'axios'
import addr from './url'

let obj=null;
import qs from 'qs'
const service = axios.create({
  timeout: 30000,
  // withCredentials: false,
  paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
    }
})
 let get = function(item,url,params){
   if(!params){
     var params={params:{}};
   }
   params['params']['time']=(new Date()).getTime();
   delete params['params']['delProvince'];
   obj=params;
    console.log(localStorage.Urlip)
   let U = ('https://'+addr['url'][localStorage.Urlip][item].ip+addr['url'][localStorage.Urlip][item].Gateway+url).split('/');
   U[U.length-1]=encodeURIComponent(U[U.length-1])
   let URL = U.join('/');
   return service.get(URL,params);
 }
export default get
export function post(item,url,params) {
  var paramsT={token:''};
  if(!params){
  }else {
    paramsT=params;
  }
  paramsT['time']=(new Date()).getTime();
  return service.post('https://'+addr['url'][localStorage.Urlip][item].ip+addr['url'][localStorage.Urlip][item].Gateway+url+addr['url'][localStorage.Urlip][item].getParams, qs.stringify(paramsT));
}
service.interceptors.response.use(function (response) {
  return response;
  }, function (error) {
    return Promise.reject(error);
  });
