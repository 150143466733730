import Vue from 'vue'
import App from './App.vue'
// import { Button,Input,Select,Option,Checkbox,Message} from 'element-ui';
Vue.config.productionTip = false
localStorage.Urlip='prod'
// Vue.use(Button).use(Input).use(Select).use(Option).use(Checkbox)
// Vue.prototype.$message = Message;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
}).$mount('#app')
