/* eslint-disable*/
import request from '@/res'
import {post} from '@/res'
export function dspLogin(params) {
  return request('dsp','/login/aliyun',params)
}
export function testLogin(params) {
  return post('test','/login/action',params)
}
export function testLogin56(params) {
  return post('dsp2','',params)
}
