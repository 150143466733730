<template>
  <div class="main">
    <div class="logo">
      <img   src="../assets/logo.png" alt="">
      <span>汇吉云</span>
    </div>
    <img  class="png" src="../assets/loginImg.png" alt="">
    <img class="background" src="../assets/background.jpg" alt="">
  <div class="loginFrom">
    <div class="welcome">
      欢迎，登录
    </div>
    <el-input placeholder="用户名" v-model="name" @focus="showTip=false" class="nameInput">
        <el-select v-model="select" @change="changeType" slot="prepend" placeholder="请选择">
          <el-option label="医院" value="1"></el-option>
          <el-option label="企业" value="2"></el-option>
        </el-select>
      </el-input>
      <el-input type='password' :class="{'warning':showTip==true}" @focus="showTip=false" class="passwordInput" placeholder="请输入密码" v-model="password"></el-input>
      <div class="verification">
        <div id="nc"></div>
      </div>
      <div class="checkbox">
         <!-- <el-checkbox v-model="rember">记住密码</el-checkbox> -->
          <span>忘记密码? </span><el-link @click="reSetP" type="primary"> 重置密码...</el-link>
      </div>
      <div class="login">
         <el-button type="primary" :loading="loading"  @click="login">登录</el-button>
         <div class="tip" v-if="showTip">
           <img src="../assets/tip.png" alt="">
           用户名或密码不正确
         </div>
      </div>

  </div>
  <span class="bottomTip">版权所有 © 中元汇吉生物技术股份有限公司<a href="http://beian.miit.gov.cn" target="blank">渝ICP备20006187号-6</a></span>
    <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="30%"
    >
    <span>密码已超过3个月有效期，请重置密码！</span>
    <span slot="footer" class="dialog-footer">
      <el-button size='small' @click="dialogVisible = false">取 消</el-button>
      <el-button size='small' type="primary" @click="toDspReset">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import md5 from 'js-md5';
axios.defaults.withCredentials = true
import {dspLogin,testLogin} from '@/api'
console.log(axios)

export default {

  name: 'HelloWorld',
  data(){
    return {
      select:'1',
      name:'',
      password:'',
      // rember:false,
      csessionid:'',
      sig:'',
      token:'',
      status:false,
      showTip:false,
      loading:false,
      dialogVisible:false
    }
  },
  created(){
    // nc_bg
    // nc_scale
    window.onresize=function (){
      if(document.getElementsByClassName('btn_ok')[0]){
        document.getElementsByClassName('nc_bg')[0].style.width=document.getElementsByClassName('nc_scale')[0].offsetWidth-38 +'px';
        document.getElementsByClassName('btn_ok')[0].style.left=document.getElementsByClassName('nc_bg')[0].style.width;
      }
    }
    let this_=this;
    window.AWSC.use("nc", function (state, module) {
            // 初始化
            window.nc = module.init({
                // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                appkey: "FFFF0N00000000009831",
                //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
                scene: "nc_login",
                // 声明滑动验证需要渲染的目标ID。
                renderTo: "nc",
                //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                success: function (data) {
                    this_.status=true;
                    // window.console && console.log(data.sessionId)
                    // window.console && console.log(data.sig)
                    // window.console && console.log(data.token)
                    this_.sessionId=data.sessionId;
                    this_.sig=data.sig;
                    this_.token=data.token;
                },
                // 滑动验证失败时触发该回调参数。
                fail: function (failCode) {
                     this.status=false;
                     window.console && console.log(failCode)
                },
                // 验证码加载出现异常时触发该回调参数。
                error: function (errorCode) {
                     this.status=false;
                     window.console && console.log(errorCode)
                }
            });
        })
        // let obj=JSON.parse(localStorage.password);
        // if(obj.test.phone!==''){
        //   this.name=obj.test.phone;
        //   this.password=obj.test.password;
        // }

  },

  mounted(){
  },
  methods:{
    toDspReset(){
      window.open('http://dsp-jjy.zybio.com/reset/','_self')
    },
    reSetP(){
      if(this.select==1){
        window.open('https://www.zybiocloud.com/login/forget','_self')
      }else if(this.select==2){
        this.toDspReset()
      }
    },
    changeType(){
      this.reset();
      this.name='';
      this.password='';
      // let obj={};
      // if(localStorage.password){
      //   obj=JSON.parse(localStorage.password);
      //   if(data=='1'){
      //     if(obj.test.phone!==''){
      //       this.name=obj.test.phone;
      //       this.password=obj.test.password;
      //     }
      //   }else if(data=='2') {
      //     if(obj.dsp.username!==''){
      //       this.name=obj.dsp.username;
      //       this.password=obj.dsp.password;
      //     }
      //   }
      // }
    },
    reset(){
      this.sessionId='';
      this.sig='';
      this.token='';
      this.status=false;
      window.nc.reset()
    },
    login(){
      if(this.loading){
        return
      }
      if(this.name==''){
        this.$message({
          message:'请输入用户名！',
          type:'warning'
        })
        return
      }
      if(this.password==''){
        this.$message({
          message:'请输入密码',
          type:'warning'
        })
        return
      }
      if(!this.sessionId){
        this.$message({
          message:'请使用滑动验证！',
          type:'warning'
        })
        return
      }
      this.loading=true;
      // if(this.rember){
      //   let obj={};
      //   if(localStorage.password){
      //     obj=JSON.parse(localStorage.password);
      //   }else {
      //     obj={
      //       test:{
      //         phone:'',
      //         password:''
      //       },
      //       dsp:{
      //         username:'',
      //         password:''
      //       }
      //     }
      //   }
      //   if(this.select==='2'){
      //     obj.dsp.username=this.name;
      //     obj.dsp.password=this.password;
      //   }else {
      //     obj.test.phone=this.name;
      //     obj.test.password=this.password;
      //   }
      //   // console.log(obj)
      //   localStorage.password=JSON.stringify(obj);
      //   // console.log(obj)
      // }
      let obj={};
      if(this.select==='2'){
        obj.aliyun_scene="nc_login";
        obj.aliyun_sessionId=this.sessionId;
        obj.aliyun_sig=this.sig;
        obj.aliyun_token=this.token;
        obj.username=this.name;
        obj.password=md5(this.password);
        dspLogin({params:obj}).then(res=>{
          // console.log(res.data.code)
          if(res.data.code=='200'){
            window.open('https://dsp-jjy.zybio.com?singleToken='+res.data.data.token+'&is_ji=1','_self')
              // window.open('https://dsp-test.zybio.com?singleToken='+res.data.data.token+'&is_ji=1','_self')
          }else {
            this.reset();
            this.loading=false;
            if(res.data.code=='40101'){
              this.$message({
                message:res.data.message,
                type:'warning'
              })
            }else if(res.data.code=='40102'){
              this.dialogVisible=true;
              return
            }else {
              this.showTip=true;
            }
          }
        }).catch(()=>{
          this.loading=false;
        })
      }else{
        obj.phone=this.name;
        obj.password=this.password;
        obj.is_ji = 1;
        testLogin(obj).then(res=>{
          // console.log(res.data.jumpurl)
          if(res.data.msg==='登录成功'){
            window.open(res.data.jumpurl,'_self')
          }else if(res.data.msg==='帐号或密码错误') {
            this.showTip=true;
            this.reset();
          }else {
            this.reset();
            this.$message({
                message:res.data.msg,
                type:'warning'
              })
          }
          this.loading=false;
        }).catch(()=>{
          this.loading=false;
        })
      }

      // dspLogin()
      // axios({
      //   method:'post',
      //   url:'http://www.jianyan.com/login/action',
      //   data:{
      //     phone:'14440000777',
      //     password:'testjy360'
      //   }
      // }).then(res=>{
      //   console.log(res)
      // })
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.el-select .el-input {
  font-size: 14px;
   width: 70px;
   .el-input__inner {
     padding-left: 10px;
     padding-right: 10px;
   }
 }
 // .input-with-select .el-input-group__prepend {
 //   background-color: #fff;
 // }
 .main {
   width: 100%;
   height: 100%;
   position: relative;
   overflow: hidden;
   .background {
     position: absolute;
     left: 0;
     top: 0;
     z-index: 1;
     width: 100%;
     height: 100%;
   }
   .png {
     position: absolute;
     left: 10%;
     top: 50%;
     transform: translateY(-50%);
     z-index: 2;
     width: 40%;
   }
   .logo {
      position: absolute;
      z-index: 2;
      position: absolute;
      left: 3.8%;
      top: 6%;

      &>img{
        vertical-align: top;
        width: 30px;
      }
      span {
        vertical-align: top;
        font-size: 25px;
        position: relative;
        top: -2px;
        font-weight: 500;
        color: #1f74ba;
      }
   }
   .loginFrom {
     padding: 49px 61px;
     position: absolute;
     top: 50%;
     right: 15%;
     transform: translateY(-50%);
     width: 442px;
     height: 460px;
     background-color: #FFF;
     z-index: 3;
     border-radius: 14px;
     box-shadow: 0px 0px 50px 0px rgba(209, 228, 238, 0.56);
     @media screen and (max-height:900px) {
       padding: 47px 46px;
       width: 368px;
       height: 430px;
      }
     .checkbox {
      padding-top: 10px;
      text-align: left;
      color: #858585 !important;
      font-size: 14px;
       &>span {
         vertical-align: top;
       }
       &>a {
         vertical-align: top;
       }
     }
     .login {
       position: relative;
       margin-top: 20px;
       .tip {
         position: absolute;
         padding-top: 10px;
         text-align: left;
         font-size: 12px;
         color: #e84040;
         left:0px;
         bottom: -23px;
         img {
           transform: translateY(1px);
         }
       }
     }

     .verification {
       width: 100%;
       height: 40px;
       position: relative;
       .nc-container #nc_1_wrapper {
         width: 100%;
       }
       .nc-container .nc_scale span {
         height: 40px;
       }
       #nc_1_n1t {
         height: 40px;
       }
       .nc-lang-cnt {
         height: 40px;
       }
       .nc-container .nc_scale span {
         line-height: 40px;
       }
     }
     .welcome {
       font-size: 30px;
       color: #000000;
       text-align: left;
       padding-bottom: 30px;
       font-weight: 500;
     }
     .password,.name {
       font-size: 14px;
       text-align: left;
       padding: 8px 0;
       color: #000000;
       opacity: 0.65;
     }
     .nameInput,.passwordInput {
      margin-bottom: 24px;
     }
     .el-button {
       width: 100%;
       height: 40px;
       border-radius: 4px;
       overflow: hidden;
       background-image: linear-gradient(264deg, #1a6eb5 0%, #3ca0dd 100%);
       font-size: 14px;
     }
   }
   .el-input__inner {
     border-color: rgba(0, 0, 0, 0.25);
   }
   .el-input-group__append, .el-input-group__prepend {
     border-color: rgba(0, 0, 0, 0.25);
     background-color: transparent;
   }
   .el-checkbox {
     color: #858585;
   }
   .el-checkbox__inner {
     border-color: #bfbfbf;
   }
   .warning {
     .el-input__inner {
       border: solid 1px #e84040;
       background-color: rgba(232, 64, 64, 0.1);
     }
   }
   .bottomTip {
      color: #6c7d94;
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      z-index: 2;
      font-size: 12px;
      a {
        margin-left: 10px;
        color:#6c7d94;
        text-decoration:none;
      }
   }
   .el-input-group__append, .el-input-group__prepend {
     background: none;
   }
 }
  .el-dialog {
    text-align: left;
    border-radius:4px;
  }
  .el-dialog__body {
    padding: 9px 20px;
    font-size: 16px;
  }

</style>
