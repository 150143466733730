const url = {
  // dev:{
  //   test:{
  //     ip:'open.jianyan.com',
  //     Gateway:''
  //   },
  //   dsp:{
  //     ip:'dsp-test.zybio.com',
  //     Gateway:'/api/base'  //网关
  //   },
  //   dsp2:{
  //     ip:'dsp-test.zybio.com',
  //     Gateway:'/upgrade/addOrUpdateVersion'  //网关
  //   },
  // },
  prod:{
    test:{
      ip:'www.zybiocloud.com',
      port:'',
      Gateway:'',
      getParams:'?json=1'
    },
    dsp:{
      ip:'dsp-jjy.zybio.com',
      port:'',
      Gateway:'/api/base',  //网关
      getParams:''
    },
  },
}
export default {url}
